import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import cn from "classnames";

import Layout from "../components/layout";
import CtaSection from "../components/CtaSection";
import { CodePreview } from "../components/docs/CodePreview";
import { CheckList, CheckListItem } from "../components/CheckList";

import IconShield from "../images/inlinesvgs/icons/shield-check.svg";
import IconTimer from "../images/inlinesvgs/icons/timer.svg";
import IconStar from "../images/inlinesvgs/icons/star.svg";
import IconHandCoins from "../images/inlinesvgs/icons/hand-coins.svg";
import IconDev from "../images/inlinesvgs/icons/dev.svg";
import IconBracketsCurly from "../images/inlinesvgs/icons/brackets-curly.svg";
import IconKey from "../images/inlinesvgs/icons/key.svg";
import IconArrowRight from "../images/inlinesvgs/icons/arrow-right.svg";

const RadioChoice = ({ children, checked, className, ...rest }) => (
	<label className={cn("formRadio", className)}>
		<input type="radio" checked={checked} {...rest} />
		<span className="formRadio-indicator" />
		<span className="formRadio-description">{children}</span>
	</label>
);

const EmailSendingApi = ({ data }) => {
	const [selectedCodeExample, setSelectedCodeExample] = React.useState(
		"template"
	);

	const codeExamples = {
		template: `await sidemail.sendMail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	templateName: "Single sign-on",
	templateProps: { url: "https://your.app/sso?token=123" },
});`,
		scheduled: `await sidemail.sendMail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	templateName: "Welcome",
	templateProps: { firstName: "Patrik" },
	// Deliver email in 60 minutes from now
	scheduledAt: "${new Date(new Date().getTime() + 60 * 60000).toISOString()}"
});`,
		markdown: `
const markdown = \`
Hello world, {name}! 🖐

Lorem ipsum dolor sit amet, consectetur adipiscing elit.
	
[$btn Example button]({link})
	
Nam vulputate fringilla vestibulum. Nulla eu lobortis enim. Praesent varius, dui quis porta pretium, mi ex ultricies enim, sed volutpat purus erat vel nibh. 
\`.trim();
	
await sidemail.sendEmail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	subject: "Testing markdown emails 😊",
	markdown,
	templateProps: {
		name: "John",
		link: "https://example.com",
	},
});
`.trim(),
		html: `await sidemail.sendMail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	subject: "Testing html only custom emails :)",
	html: "<html><body><h1>Hello world! 👋</h1><body></html>"
});`,
		plaintext: `await sidemail.sendMail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	subject: "Testing plain-text only custom emails :)",
	text: "Hello world! 👋"
});`,
	};

	return (
		<Layout
			pageMeta={{
				title: "Email sending API – Sidemail",
				description:
					"Sidemail makes implementation of transactional emails such as password resets, receipts and similar to your SaaS seamless. Use pre-built email templates or create your own with no-code editor. Send with our API.",
			}}>
			<div id="email-sending-api">
				<section className="aboveFold">
					<div className="container">
						<h2 className="text-xsmall uptitle uptitle--new m-0">
							Transactional emails
						</h2>

						<h1>Email sending&nbsp;API</h1>

						<p className="maxWidth-750 itemCenter mb-30 text-lead">
							Sidemail makes it incredibly easy to create, maintain, analyze,
							and deliver beautiful transactional emails via API — start sending
							transactional emails like password reset, welcome, and more from
							your application in just a few minutes.
						</p>

						<a
							className="button button-primary"
							href="https://client.sidemail.io/register">
							Get started
							<IconArrowRight className="u-ml1" width={14} height={14} />
						</a>
					</div>
				</section>

				<section className="container">
					<div className="twoColumns twoColumns--reversed">
						<div className="twoColumns-text">
							<h2 className="title-large">
								Email delivery that you can count on
							</h2>

							<p className="text">
								Nobody likes to wait for a password reset email that takes too
								long to deliver. Even worse, if it ends up in the spam folder,
								you can't find it, and when you do, it's already expired.
							</p>

							<p className="text">
								Sidemail will help you to solve this. No more upset customers
								that didn't receive their email on time, every time.
							</p>
						</div>

						<div className="twoColumns-image box-lightBackground maxWidth-440">
							<h3 className="uptitle text-xsmall text-light mb-30">
								Key benefits
							</h3>

							<div className="valuesWrapper mb-20">
								<IconShield className="valuesIcon" />
								<div className="valuesText text-dark u-text-bold">
									Reliable email delivery
								</div>
							</div>

							<div className="valuesWrapper mb-20">
								<IconTimer className="valuesIcon" />
								<div className="valuesText text-dark u-text-bold">
									Fastest transactional emails
								</div>
							</div>

							<div className="valuesWrapper mb-20">
								<IconStar className="valuesIcon" />
								<div className="valuesText text-dark u-text-bold">
									Best practices for preventing spam folder
								</div>
							</div>

							<div className="valuesWrapper">
								<IconHandCoins className="valuesIcon" />
								<div className="valuesText text-dark u-text-bold">
									Minimizing your costs
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="api-simple-to-get-started" className="container">
					<h2 className="title-large">Simple to integrate</h2>
					<p>
						Start sending emails from your application in just a few minutes
						thanks to Sidemail API—modern JSON API for sending transactional
						emails that is rich in features, well documented, and designed with
						developer experience in mind.{" "}
						<Link to="/docs/email-sending-quickstart">Visit the docs</Link>.
					</p>

					<div className="codeExampleSection">
						<div>
							<div className="uptitle text-xsmall text-light mb-30">
								Code examples
							</div>

							<RadioChoice
								checked={selectedCodeExample === "template"}
								onChange={() => setSelectedCodeExample("template")}>
								Send no-code email template
							</RadioChoice>

							<RadioChoice
								checked={selectedCodeExample === "markdown"}
								onChange={() => setSelectedCodeExample("markdown")}>
								Send email with markdown
							</RadioChoice>

							<RadioChoice
								checked={selectedCodeExample === "scheduled"}
								onChange={() => setSelectedCodeExample("scheduled")}>
								Schedule email delivery
							</RadioChoice>

							<RadioChoice
								checked={selectedCodeExample === "html"}
								onChange={() => setSelectedCodeExample("html")}>
								Send custom HTML email
							</RadioChoice>

							<RadioChoice
								checked={selectedCodeExample === "plaintext"}
								onChange={() => setSelectedCodeExample("plaintext")}>
								Send custom plain text email
							</RadioChoice>
						</div>

						<div>
							<CodePreview language="javascript">
								{codeExamples[selectedCodeExample]}
							</CodePreview>
						</div>
					</div>
				</section>

				<section className="container">
					<div className="twoColumns">
						<div className="twoColumns-text">
							<h2 className="title-large">No-code email editor</h2>

							<p className="text">
								Built for the ultimate workflow, the Sidemail no‑code email
								editor lets you create reusable email templates, such as
								password reset. Then, in your application, you reference the
								email template either by name or its ID when making the API
								request.
							</p>

							<CheckList>
								<CheckListItem>
									Upload a logo, and match brand colors
								</CheckListItem>
								<CheckListItem>All email clients supported</CheckListItem>
								<CheckListItem>Pre-generated API code snippets</CheckListItem>
							</CheckList>
						</div>

						<div className="twoColumns-imageWrapper">
							<div className="twoColumns-imageWrapperInner">
								<Img fluid={data.noCodeEditorImage.childImageSharp.fluid} />
							</div>
						</div>
					</div>
				</section>

				<section id="premade-templates" className="container">
					<h2 className="title-large">Pre‑made email templates</h2>
					<p>
						To give you the best starting point, we researched hundreds of
						emails and crafted a well-spelled email for every occasion. And, if
						you need it, you can modify every bit of it.{" "}
						<a href="/transactional-email-templates/">
							Browse transactional email templates
						</a>
						.
					</p>
					<div style={{ margin: "0 auto", maxWidth: 800 }}>
						<ul className="box u-text-bold">
							<li>Account activation</li>
							<li>Receipt</li>
							<li>Message notification</li>
							<li>Canceled subscription</li>
							<li>Usage warning</li>
							<li>Sign in alert</li>
							<li>Single sign-on</li>
							<li>Welcome</li>
							<li>Billing issue</li>
							<li>Trial expired</li>
							<li>Product feedback</li>
							<li>Password reset</li>
						</ul>
					</div>
				</section>

				<section className="container">
					<div className="twoColumns">
						<div className="twoColumns-text">
							<h2 className="title-large">Key metrics</h2>

							<p className="text">
								Take out the guesswork and learn how your users interact with
								emails. Analyze charts and get a broad understanding of your
								email delivery or visit sending history to gain in-depth
								insights into a specific email.
							</p>

							<CheckList>
								<CheckListItem>Total deliveries and recipients</CheckListItem>
								<CheckListItem>
									Bounces, spam complaints and opens
								</CheckListItem>
								<CheckListItem>
									Individual email preview in sending history
								</CheckListItem>
							</CheckList>
						</div>

						<div className="twoColumns-imageWrapper">
							<div className="twoColumns-imageWrapperInner">
								<Img fluid={data.keyMetricsImage.childImageSharp.fluid} />
							</div>
						</div>
					</div>
				</section>

				<section className="container">
					<div className="twoColumns">
						<div className="twoColumns-text">
							<h2 className="title-large">Sending history</h2>

							<p className="text">
								Get a comprehensive overview of every email you send. See the
								email preview, timeline, status, and more. Want to see
								information about the specific email you sent year ago? No
								problem, Sidemail gives you unlimited access to email history.
							</p>

							<CheckList>
								<CheckListItem>
									Unlimited searchable email history
								</CheckListItem>
								<CheckListItem>30-day full email preview</CheckListItem>
								<CheckListItem>Email activity timeline</CheckListItem>
							</CheckList>
						</div>

						<div className="twoColumns-imageWrapper">
							<div className="twoColumns-imageWrapperInner">
								<Img fluid={data.sendingHistoryImage.childImageSharp.fluid} />
							</div>
						</div>
					</div>
				</section>

				<section className="moreFeaturesSection container">
					<ul>
						<li className="valuesWrapper mb-20">
							<IconShield className="valuesIcon" />
							<div className="valuesText">
								<h4 className="text-dark u-text-bold mb-8">
									Uncompromising quality
								</h4>
								<div className="text-small text-light">
									Sidemail is built upon Amazon’s robust infrastructure
									combining in‑house configurations it ensures highly reliable
									email delivery.
								</div>
							</div>
						</li>

						<li className="valuesWrapper mb-20">
							<IconKey className="valuesIcon" />
							<div className="valuesText">
								<h4 className="text-dark u-text-bold mb-8">
									Your domain & DKIM
								</h4>
								<div className="text-small text-light">
									Use your own domain for sending. To establish the best
									delivery results, we automatically set up for you DKIM and
									SPF.
								</div>
							</div>
						</li>

						<li className="valuesWrapper mb-20">
							<IconBracketsCurly className="valuesIcon" />
							<div className="valuesText">
								<h4 className="text-dark u-text-bold mb-8">
									Easy-to-use variables
								</h4>
								<div className="text-small text-light">
									Add recipient-specific information to your email by using
									variables. This is useful for links, dates, amounts, etc.
								</div>
							</div>
						</li>

						<li className="valuesWrapper mb-20">
							<IconDev className="valuesIcon" />
							<div className="valuesText">
								<h4 className="text-dark u-text-bold mb-8">Focus on DX</h4>
								<div className="text-small text-light">
									We continuously push boundaries and focus on developing
									features that make the work of developers and product owners
									easier.
								</div>
							</div>
						</li>

						<li className="valuesWrapper mb-20">
							<IconTimer className="valuesIcon" />
							<div className="valuesText">
								<h4 className="text-dark u-text-bold mb-8">
									First‑principles simplicity
								</h4>
								<div className="text-small text-light">
									Sidemail is one of the most simple yet complete email
									providers on the market. The whole integration process takes
									about 30 minutes.
								</div>
							</div>
						</li>

						<li className="valuesWrapper mb-20">
							<IconStar className="valuesIcon" />
							<div className="valuesText">
								<h4 className="text-dark u-text-bold mb-8">
									5-star rated support
								</h4>
								<div className="text-small text-light">
									If needed, you'll get personal help from real people, directly
									from the development team.
								</div>
							</div>
						</li>
					</ul>
				</section>

				<CtaSection />
			</div>
		</Layout>
	);
};

export default EmailSendingApi;

export const query = graphql`
	query {
		noCodeEditorImage: file(
			relativePath: { eq: "sidemail-public--api-nocode.png" }
		) {
			...twoColumnsImage
		}
		keyMetricsImage: file(
			relativePath: { eq: "sidemail-public--api-metrics.png" }
		) {
			...twoColumnsImage
		}
		sendingHistoryImage: file(
			relativePath: { eq: "sidemail-public--api-history.png" }
		) {
			...twoColumnsImage
		}
	}
`;
